<template>
  <div class="device-details">
    <Teleport to="#header-buttons">
      <div>
        <b-button
          id="device-btn"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="ml-2"
          variant="primary"
          @click="goToCreateCampaign"
        >
          Book Now
        </b-button>
      </div>
    </Teleport>
    <b-card
      no-body
      class="overflow-hidden"
    >
      <b-container fluid>
        <b-skeleton-wrapper :loading="loader">
          <template #loading>
            <!--Image Section-->
            <b-row class="mt-2">
              <b-col
                lg="6"
                md="6"
                xl="6"
              >
                <b-skeleton
                  type="button"
                  width="150px"
                />
              </b-col>
              <b-col
                lg="1"
                md="1"
                xl="1"
                class="px-0 mx-0 pl-1"
              >
                <b-skeleton
                  height="35px"
                  width="65px"
                  class="pl-2 mx-0"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                lg="6"
                md="6"
                xl="6"
              >
                <b-skeleton-img
                  aspect="1:1"
                  card-image="left"
                />
              </b-col>
              <b-col
                lg="6"
                xl="6"
                md="6"
              >

                <!--First Section-->
                <b-skeleton
                  animation="wave"
                  width="85%"
                />
                <b-skeleton
                  animation="wave"
                  width="55%"
                />
                <b-skeleton
                  animation="wave"
                  width="70%"
                />
                <b-skeleton
                  animation="wave"
                  width="85%"
                />
                <div class="px-1">
                  <hr class="mb-2">
                </div>
                <b-skeleton
                  animation="wave"
                  width="55%"
                />
                <b-skeleton
                  animation="wave"
                  width="70%"
                />
                <b-skeleton
                  animation="wave"
                  width="85%"
                />
                <b-skeleton
                  animation="wave"
                  width="55%"
                />
                <div class="px-1">
                  <hr class="mb-2">
                </div>
                <!--Third Section-->
                <b-skeleton
                  animation="wave"
                  width="70%"
                />
                <b-skeleton
                  animation="wave"
                  width="85%"
                />

                <!--Last Section-->
                <b-skeleton
                  animation="wave"
                  width="45%"
                />
                <b-skeleton
                  animation="wave"
                  width="55%"
                />
                <div class="px-1">
                  <hr class="mb-2">
                </div>
                <b-skeleton
                  animation="wave"
                  width="30%"
                />
                <b-skeleton
                  animation="wave"
                  width="30%"
                />
                <b-skeleton
                  animation="wave"
                  width="30%"
                />
              </b-col>
            </b-row>

          </template>
          <b-row class="pt-2">
            <b-col
              lg="6"
              md="6"
              xl="6"
              sm="4"
              class="device-name"
            >
              <span
                class="device-info-heading text-primary"
              >
                {{ device.name }}
                <span />
              </span></b-col>
            <!-- Device Name and Edit Button -->
            <b-col
              xl="3"
              lg="3"
              md="3"
              sm="4"
              style="display: inline;"
              class="mt-0 p-0 device-id"
            >
              <b-card-title
                class="m-0 p-0 device--title"
                style="display: inline;"
              >
                <span class="text-primary ml-1">#{{ device.id }}</span>
              </b-card-title>
            </b-col>
          </b-row>
          <!-- align-h="start" -->
          <!-- Image section-->
          <b-row class="pb-2 pt-0">
            <b-col
              class="pt-2"
              xl="6"
              lg="6"
              md="6"
            >
              <b-carousel
                v-if="device.images.length > 0"
                id="carousel-1"
                v-model="slide"
                :interval="3000"
                :controls="false"
                :indicators="false"
                style="background-color: #F8F8F8; position: relative; width: 95%;"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
              >
                <b-carousel-slide
                  v-for="(image, index) in device.images"
                  :key="index"
                >
                  <!-- :img-src="image" -->
                  <template #img>
                    <div class="test-1">
                      <b-img
                        :src="image"
                        alt="image"
                        fluid
                        rounded
                      />
                    </div>
                  </template>
                </b-carousel-slide>
              </b-carousel>
              <AddImage
                :spinner="spinner"
                style="z-index:5; position: relative; background-color: rgba(0, 0, 0, 0.5); "
                :images-length="2"
                :view-device-details="false"
              />
            </b-col>
            <!--Device Analytics-->
            <b-col
              class="pt-2 text-container"
              xl="6"
              lg="6"
              md="6"
            >
              <b-card-body class="p-0">
                <b-card-text>
                  <!--Location Name-->
                  <span
                    v-if="device.user_has_group.name"
                    class="mb-1 device-info-heading-1 text-primary"
                  >
                    Business Name : <span
                      v-if="device.user_has_group.name"
                      class="device-info-text-1 text-primary"
                    >{{
                      device.user_has_group.name
                    }}</span>
                  </span>
                  <!--Device Contact Information-->
                  <!--Border Line Div-->
                  <div class="p-1">
                    <hr class="mb-2">
                  </div>
                  <!--Venue Section-->
                  <div class="pb-2">
                    <span class="device-info-heading">Venue Name: </span>
                    <span
                      v-if="device.venue.venue_name"
                      class="device-info-text  ml-1"
                    >{{ device.venue.venue_name }}</span>
                    <br>
                    <span class="device-info-heading">Venue Type - </span>
                    <span class="device-info-text  ml-1">{{ device.venue.type? device.venue.type.venue_type_name : null }}</span>
                    <br>
                    <span class="device-info-heading">Venue Email - </span>
                    <span class="device-info-text  ml-1">{{ device.venue.contact_email }}</span>
                    <br>
                    <span class="device-info-heading">Venue Phone Number - </span>
                    <span class="device-info-text  ml-1">{{ device.venue.number }}</span>
                    <br>
                    <span class="device-info-heading">Venue Address: </span>
                    <span class="device-info-text  ml-1">{{ device.venue.address }}</span>
                    <br>
                    <span class="device-info-heading-3">Venue Operating Hours </span>
                    <br>
                    <span class="device-info-heading-1">
                      Venue Start Time: </span>
                    <span class="device-info-text  ml-1">{{ device.venue.start_time }}</span>
                    <br>
                    <span class="device-info-heading-1">
                      Venue End Time: </span>
                    <span class="device-info-text  ml-1">{{ device.venue.end_time }}</span>
                    <br>
                    <!--Border Line Div-->
                    <div class="px-1">
                      <hr class="mb-2">
                    </div>
                    <!--Operating Hours-->
                    <span class="device-info-heading-3">Screen Operating Hours </span>
                    <br>
                    <span class="device-info-heading-1">
                      Start Time: </span>
                    <span class="device-info-text  ml-1">{{ device.op_start_time }}</span>
                    <br>
                    <span class="device-info-heading-1">
                      End Time: </span>
                    <span class="device-info-text  ml-1">{{ device.op_end_time }}</span>
                    <br>
                    <!--Venue Impression details-->
                    <span class="device-info-heading">Height: </span>
                    <span class="device-info-text  ml-1">{{ device.height }}px</span>
                    <span class="ml-4 device-info-heading">Width: </span>
                    <span class="device-info-text  ml-1">{{ device.width }}px</span>
                    <br>
                    <span class="venue-heading-2">Supports: </span>
                    <b-badge
                      v-if="device.video_support"
                      class="badge ml-2"
                      variant="light-success"
                    >
                      Video
                    </b-badge>
                    <b-badge
                      v-if="device.image_support"
                      class="badge ml-1"
                      variant="light-warning"
                    >
                      Images
                    </b-badge>
                  </div>
                  <div class="px-1">
                    <hr class="mb-2">
                  </div>
                  <b-col
                    cols="12"
                  >
                    <div>
                      <div class="device-info-heading-3 d-flex justify-content-between">
                        <span class="pt-1">
                          Audience Profile
                        </span>
                        <span
                          v-if="shouldEditAudienceProfileShow"
                        >
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="flat-primary"
                            size="sm"
                            class="btn-icon rounded-circle"
                            @click="openEditAudienceProfileModal"
                          >
                            <feather-icon icon="Edit2Icon" />
                          </b-button>
                        </span>
                      </div>
                      <div
                        class="tab-content-wrapper mt-1"
                      >
                        <div
                          v-html="device.audience_profile ? device.audience_profile : 'Nothing to Show'"
                        />
                      </div>
                    </div>
                  </b-col>
                  <div class="px-1">
                    <hr class="mb-2">
                  </div>
                  <div>
                    <span class="device-info-heading">Active Status: </span>
                    <span>
                      <b-badge
                        class="badge  ml-1"
                        :variant="device.status === DeviceStatus.ACTIVE ? 'light-success' : 'light-danger'"
                      >{{
                        device.status === DeviceStatus.ACTIVE ? DeviceStatus.ACTIVE : DeviceStatus.INACTIVE }}
                      </b-badge>
                    </span>
                  </div>
                  <div>
                    <span class="device-info-heading-1">Latitude: </span>
                    <span class="device-heading-text  ml-1">{{ device.latitude }}</span>
                    <span class="device-info-heading-1 ml-2">Longitude: </span>
                    <span class="device-heading-text  ml-1">{{ device.longitude }}</span>
                  </div>
                </b-card-text>
              </b-card-body>
              <div>
                <span class="device-info-heading-1">Last Updated: </span>
                <span class="device-info-tex  ml-1">{{ lastUpdatedDate }}</span>
              </div>
              <div class="px-1">
                <hr class="mb-2">
              </div>
              <span class="device-info-heading-3 mb-2">Screen Impressions Info </span>
              <b-row
                class=" pt-1"
              >
                <b-col
                  align-self="start"
                  cols="8"
                  order="12"
                >
                  <div class="d-flex justify-content-between align-items-center">
                    <h5
                      style="font-weight: bold; display: inline;"
                      class="text-primary"
                    >
                      Impressions : {{ parseInt(device.impressions) }}
                    </h5>
                  </div>
                </b-col>
                <b-col
                  align-self="end"
                  cols="4"
                  order="12"
                >
                  <div class="d-flex justify-content-between align-items-center">
                    <span
                      style="font-weight: bold; display: inline;"
                      class="text-primary"
                    >
                      CPM : ${{ parseInt(device.cpm) }}
                    </span>
                  </div>
                </b-col>
              </b-row>
              <div class="px-1">
                <hr class="mb-2">
              </div>
            </b-col>
          </b-row>
        </b-skeleton-wrapper>
      </b-container>
    </b-card>
    <b-card>
      <b-row>
        <b-col
          lg="6"
          md="6"
        >
          <b-skeleton-img
            v-if="loader"
            no-aspect
            height="300px"
          />
          <CardEarningReports
            v-else
            :stats-data="device ? device.earningDetails : null"
            :date-sequence="dateSequence"
            :earning-sequence="earningSequence"
          />
        </b-col>
        <b-col
          lg="6"
          md="6"
        >
          <b-skeleton-img
            v-if="loader"
            no-aspect
            height="300px"
          />
          <ExternalAdsTracker
            v-else
            :stats-data="device ? device.campaignTracker : null"
          />
        </b-col>
      </b-row>
    </b-card>
    <CardSelectGroupModal
      ref="external-admin-select-group"
    />
    <CardEditAudienceProfile
      ref="edit-device-audience-profile"
      type="Device"
      :device-id="$route.params.deviceId"
      @refresh-data="refreshData"
    />
  </div>
</template>
<script>
import {
  BCard, BBadge, BDropdown, BDropdownItem, BCardImg, BCardTitle, BCardHeader, BCardBody, BMedia,
  BMediaAside, BMediaBody, BLink, BImg, BButton, BAvatar, BSkeletonTable, BProgress, BProgressBar,
  BContainer, BRow, BCol, BOverlay,
  BSkeleton, BSkeletonWrapper, BSkeletonImg, BFormCheckbox, BCardText, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import Vue from 'vue'
import Teleport from 'vue2-teleport'
import AccountTypes from '@/common/enums/accountTypeEnum'
import DeviceDropdown from '@/components/devices-group/DeviceDropdown.vue'
import AddImage from '@/components/mediastorage/AddImage.vue'
import DeviceStatus from '@/common/enums/deviceStatusEnum'
import CardEarningReports from '@/components/common/CardEarningReports.vue'
import ExternalAdsTracker from '@/components/account-setting/ExternalAdsTracker.vue'
import CardSelectGroupModal from '@/components/common/CardSelectGroupModal.vue'
import CardEditAudienceProfile from '@/components/common/CardEditAudienceProfile.vue'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'

Vue.directive('ripple', Ripple)
export default {
  /* eslint-disable */
      components: {
        BOverlay,
        AddImage,
        BDropdown,
        BDropdownItem,
        BCard,
        BBadge,
        BContainer,
        BRow,
        BCol,
        BProgress,
        BProgressBar,
        BCardTitle,
        BCardHeader,
        BCardText,
        BCardBody,
        BCardImg,
        BMedia,
        BMediaAside,
        BMediaBody,
        BLink,
        BImg,
        BSkeleton,
        BSkeletonWrapper,
        BButton,
        BAvatar,
        BSkeletonTable,
        DeviceDropdown,
        BSkeletonImg,
        BFormCheckbox,
        BCarousel,
        BCarouselSlide,
        CardEarningReports,
        ExternalAdsTracker,
        Teleport,
        CardSelectGroupModal,
        CardEditAudienceProfile,
      },
      directives: {
        Ripple,
      },
      /* eslint-enable */
  emits: ['load-device', 'load-data', 'referesh-data'],
  data() {
    return {
      DeviceStatus,
      slide: 0,
      sliding: null,
      spinner: false,
      deviceDataLocation: {},
      AccountTypes,
      loader: false,
      device: null,
      date: new Date(),
      campaignTracker: {
        totalCampaignCount: 11,
        totalCompletedAdsCount: 3,
        totalCompletedPmpAds: 3,
        totalInProgressPmpAds: 2,
      },
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters['user/getUserRole']
    },
    lastUpdatedDate() {
      return moment(this.device.last_updated_date).utc().format('ddd, MMM Do YYYY, HH:mm:ss')
    },
    dateSequence() {
      return this.device?.earningDetails ? this.device.earningDetails.last7DaysEarningsArray.map(item => item.date) : []
    },
    earningSequence() {
      return this.device?.earningDetails ? this.device.earningDetails.last7DaysEarningsArray.map(item => item.earning) : []
    },
    isUserAdmin() {
      return this.$store.getters['user/getUserRole'] === AccountTypes.ADMIN
    },
    isAgency() {
      return this.$store.getters['user/getSelectedGroupMemberPermissions'].includes(MemberPermissions.AGENCY)
    },
    shouldEditAudienceProfileShow() {
      if (!this.isAgency && !this.isUserAdmin) {
        return this.$route.name === 'dashboard-venue-device-details'
      }
      return false
    },
  },
  async mounted() {
    this.getDeviceDetails()
  },
  methods: {
    async getDeviceDetails() {
      this.loader = true
      this.spinner = true
      if (this.currentUser === AccountTypes.ADMIN) {
        this.device = await this.$store.dispatch('devices/getUserScreenDetailsAdmin', { id: this.$route.params.deviceId })
      } else {
        this.device = await this.$store.dispatch('devices/getUserScreenDetails', { id: this.$route.params.deviceId })
      }
      this.loader = false
      this.spinner = false
    },
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    goToCreateCampaign() {
      const venue = {
        ...this.device.venue,
        device: [this.device],
      }
      this.$store.commit('pmpAds/SET_SELECTED_DEVICES_ON_DASHBOARD', [venue])
      if (this.isUserAdmin) {
        if (this.$route.name === 'simi-network-single-venue-user') {
          this.$router.push({
            name: 'admin-user-external',
            params: {
              userId: this.$route.params.userId,
            },
          })
        } else if (this.$route.name === 'user-single-venue-screen-details') {
          this.$router.push({
            name: 'admin-user-external',
            params: {
              userId: this.$route.params.userId,
            },
          })
        } else {
          this.$refs['external-admin-select-group'].showLocationModal()
        }
      } else if (this.$route.name === 'dashboard-venue-device-details') {
        this.$router.push({
          name: 'dashboard-venue-device-externalAds',
          params: {
            venueId: this.$route.params.venueId,
            deviceId: this.$route.params.deviceId,
          },
        })
      } else {
        this.$router.push({ name: 'simi-network-pmp-ads-edit' })
      }
    },
    openEditAudienceProfileModal() {
      this.$refs['edit-device-audience-profile'].showCpmModal(this.device.audience_profile)
    },
    async refreshData() {
      await this.getDeviceDetails()
    },
  },
}
</script>
    <style scoped lang="scss">
    .device-details {
      .image-div {
        object-fit: cover;
        width: 100%;
        // height: 100% !important;
      }
      .tab-content-wrapper {
        padding: 1.5rem;
        border-radius: 0 0 6px 6px;
        background: #f8f8f8;
        min-height: 150px !important;
      }
      .test-1 {
        width: 100%;
        height: calc(100vmin - 100px);
        object-fit: fill;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        justify-items: center;
      }
      .device-info-heading {
        // padding-left: 2px;
        font-size: 14px;
        line-height: 21px;
        color: #6e6b7b;
        font-family: "Montserrat";
        font-weight: 700;
        font-style: normal;
      }
      //Second Heading for device
      .device-info-heading-1 {
        font-size: 16px;
        line-height: 0px;
        color: #6e6b7b;
        font-family: "Montserrat";
        font-weight: 900;
        font-style: normal;
      }
      .device-info-heading-2 {
        font-size: 20px;
        line-height: 0px;
        color: #6e6b7b;
        font-family: "Montserrat";
        font-weight: 900;
        font-style: normal;
      }
      .device-info-heading-3 {
        font-size: 16px;
        line-height: 0px;
        color: #6e6b7b;
        font-family: "Montserrat";
        font-weight: 900;
        font-style: normal;
        margin-bottom: 2px !important;
      }
      .checkbox {
        // float: right;
        text-align: right !important;
        display: inline !important;
      }
      //Device Text Typography
      .device-info-text {
        // padding-left: 2px;
        font-size: 14px;
        line-height: 21px;
        color: #6e6b7b;
        font-family: "Montserrat";
        font-weight: 400;
      }
      .device-info-text-1 {
        // padding-left: 2px;
        font-size: 16px;
        line-height: 21px;
        color: #6e6b7b;
        font-family: "Montserrat";
        font-weight: 400;
      }
      .group-name {
        font-size: 14px;
        line-height: 21px;
        color: #6e6b7b;
        font-family: "Montserrat";
        font-weight: 600;
      }
      .badge {
        border-radius: 25px;
        padding-inline: .625rem;
      }
      .edit-icon {
        padding-top: 0px !important;
        margin-top: 0px !important;
      }
      .custom-control-primary {
        padding-top: 8px;
      }
      .custom-control-primary ::v-deep label.custom-control-label {
        padding: 0px !important;
      }
      .device--title {
        padding-top: 4px !important;
      }
      @media (max-width: 576px) {
      .device-name{
        width: 25%;
      }
      .device-id{
        width: 20%;
      }
      }
      @media (max-width: 460px) {
      .device-name{
        width: 50%;
      }
      .device-id{
        width: 50%;
        display: flex !important;
        justify-content: flex-end;
        padding-right: 10px !important;
        padding-top: 0px !important;
      }
      .device--title{
        padding-top: 0px !important;
      }
      .device-monetisation{
        display: flex !important;
        width: 100%;
        justify-content: flex-end;
        padding-right: 10px;
      }
      .text-body{
        margin-top: 5px;
      }
      .paddingClass{
        margin-bottom: 10px;
      }
      }
    }
    </style>
